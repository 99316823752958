import { createAction, props } from '@ngrx/store';
import { Limits } from './limits.reducer';

export const actionPrefix = '[limits]';

export const loadLimits = createAction(actionPrefix + ' Load Limits');

export const loadLimitsSuccess = createAction(actionPrefix + ' Load Limits Success', props<{ data: Limits }>());

export const loadLimitsFailure = createAction(actionPrefix + ' Load Limits Failure', props<{ error: Error }>());
